<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <div style="width: 80%; max-width: 500px; margin-bottom: 40px">
      <v-img :src="require(`@/assets/logo.png`)" />
    </div>
    <v-row justify="center" />
    <v-dialog
      v-model="showLoginDialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title
          class="grey lighten-2"
          style="padding-bottom: 20px"
        >
          {{ $t('company-invitation') }}
        </v-card-title>

        <v-card-text style="padding-top: 20px">
          {{ $t('dialog.in-order-to-accept-a-company-invitation') }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            color="primary"
            depressed
            type="submit"
            :to="test"
          >
            {{ $t('go-to-login') }}
          </v-btn>
          <v-btn
            large
            color="primary"
            depressed
            type="submit"
            to="/register"
          >
            {{ $t('register') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAcceptInvitationDialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title
          class="grey lighten-2"
          style="padding-bottom: 20px"
        >
          {{ $t('company-invitation') }}
        </v-card-title>

        <v-card-text style="padding-top: 20px">
          {{ $t('dialog.do-you-really-want-to-join-the-company') }}
        </v-card-text>
        <v-card-text class="pt-0">
          {{ $t('dialog.if-you-are-already-in-a-company-you-will-be-removed') }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            color="error"
            depressed
            type="submit"
            @click="showAcceptInvitationDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            large
            color="primary"
            depressed
            type="submit"
            @click="acceptCompanyInvitation"
          >
            {{ $t('accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import companyService from '@/shared/services/companyService'

  export default {
    name: 'PagesLogin',
    data: function () {
      return {
        showLoginDialog: false,
        showAcceptInvitationDialog: false,
        test: '/login?token=' + this.$route.params.token,
      }
    },
    mounted () {
      const account = this.$store.getters.account
      if (account) {
        this.showAcceptInvitationDialog = true
      } else {
        this.showLoginDialog = true
      }
    },
    methods: {
      acceptCompanyInvitation () {
        const token = this.$route.params.token
        if (token) {
          companyService
            .acceptCompanyInvitation(token)
            .then(result => {
              this.$router.push('/app/company-profile', () => {})
            })
            .catch(e => {
              console.error('Accept company invitation failed', e)
              this.$router.push('/app', () => {})
            })
        }
      },
    },
  }
</script>
